@tailwind base;
@tailwind components;
@tailwind utilities;

.minHeight100vh {
  min-height: calc(var(--vh, 1vh) * 100);
}

.maxHeight100vh {
  max-height: calc(var(--vh, 1vh) * 100);
}

.height100vh {
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
}

/* * {
  @apply border border-red-900;
} */

@font-face {
  font-family: "Gilroy";
  font-weight: 100;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-UltraLight.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 200;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Thin.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 300;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Light.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-ExtraBold.otf) format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  font-style: normal;
  src: url(./assets/fonts/Gilroy/Radomir-Tinkov-Gilroy-Black.otf) format("truetype");
}
